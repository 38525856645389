import styled from "styled-components"

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ece7e0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  padding: 20px;
`
export const VideoContainer = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  width: 100%;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`
