import React from "react"
import { Parallax } from "react-parallax"

import * as S from "./styles"

import badges from "./content"

const title = {
  pt: "O Fruto",
  en: "The Fruit",
  es: "El Fruto",
}

const p1 = {
  pt:
    "O açaí é um fruto consumido há muito tempo pelos indígenas e moradores da região amazônica devido as suas propriedades nutritivas. É também largamente utilizado para a produção de um refresco conhecido como vinho de açaí.",
  en:
    "Due to its nutritional properties, açaí is a fruit that has been consumed for many years by the indigenous and local inhabitants of the Amazonian region. It is widely used for the production of a beverage locally known as “açaí wine” (although it is a non-alcoholic juice).",
  es:
    "Por mucho tiempo consumido por los indígenas y los habitantes de la región amazónica debido a sus propiedades nutritivas, es también conocido por ellos como un refresco llamado “vino de açaí”.",
}

const p2 = {
  pt:
    "Nas regiões sul e sudeste vem sendo popularizado e consumido como complemento alimentar, principalmente pelas pessoas que buscam vigor físico. É rico em proteínas, fibras, lipídeos, vitamina E e minerais, além de apresentar elevado teor de pigmentos, como a antocianina, recomendados ao controle do colesterol.",
  en:
    "The consumption of açaí in the south and south-east regions of the country, has become increasingly popular, especially for people wishing to enhance physical performance. It is rich in proteins, fibers, lipids, vitamin E and minerals in addition to holding high levels of anthocyanin pigments which are recommended for cholesterol control.",
  es:
    "En las regiones sur y sudeste viene siendo popularizado y consumido como complemento alimenticio, principalmente por las personas que buscan vigor físico. Es rico en proteínas, fibras, lípidos, vitamina E y minerales, además de presentar un alto contenido de pigmentos, como la antocianina, recomendado para el control del colesterol.",
}

const p3 = {
  pt:
    "É recomendado para praticamente todos, sobretudo para os idosos e para os que têm problemas digestivos. Quem está de dieta não deve eliminá-lo completamente, pois ele é indispensável no transporte de oxigênio para as células.",
  en:
    "It is recommended for virtually everybody, especially the elderly and for individuals who have digestive problems. It can still be included in a diet regime, because it aids in the transport of oxygen to the cells.",
  es:
    "Indistintamente recomendado para todas las personas, sobre todo para los ancianos y para los que tienen problemas digestivos. Para aquellos que hacen dieta no se debe eliminar el consumo completamente, ya que el açaí es indispensable en el transporte de oxígeno a las células.",
}

const l1 = {
  pt: "Comparando a composição química da polpa do açaí",
  en: "Comparing the chemical composition of açaí",
  es: "Comparando la composición química de la pulpa del açaí",
}

const l2 = {
  pt: "com leite bovino cru, o açaí contêm:",
  en: "with dairy raw milk, açaí contains:",
  es: "con leche bovina cruda, el açaí contiene:",
}

const Fruit = ({ language }) => (
  <Parallax
    blur={0}
    bgImage={require("../../images/acai-bello-fruto-bg-acai.jpg")}
    bgImageAlt="Açaí Bello Fruto"
    strength={200}
  >
    <S.Container id="acai">
      <S.Title>{title[language]}</S.Title>
      <S.Column>
        <S.Text>{p1[language]}</S.Text>
        <S.Text>{p2[language]}</S.Text>
        <S.Text>{p3[language]}</S.Text>
      </S.Column>
      <S.Column>
        <S.Text center>
          {l1[language]}
          <br />
          {l2[language]}
        </S.Text>
        <S.BadgesWrapper>
          {badges[language].map((badge, i) => (
            <S.Badge key={i}>
              <S.BadgeTitle>{badge.title}</S.BadgeTitle>
              <S.BadgeCircle>
                <div>
                  <S.BigText>{badge.bigText}</S.BigText>
                  <S.SmallText>{badge.smallText}</S.SmallText>
                </div>
                <S.SubText>{badge.subText}</S.SubText>
              </S.BadgeCircle>
            </S.Badge>
          ))}
        </S.BadgesWrapper>
      </S.Column>
    </S.Container>
  </Parallax>
)

export default Fruit
