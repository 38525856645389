import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 980px) {
    display: block;
  }
`

export const Column = styled.div`
  width: 59%;

  &:first-of-type {
    width: 39%;
    margin-right: 2%;
  }

  @media (max-width: 980px) {
    width: 100% !important;
  }
`
export const Title = styled.h2`
  color: #fff;
  font-family: "ZonaProBlack";
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
`

export const Text = styled.p`
  padding-bottom: 16px;
  color: #fff;
  text-align: ${props => (props.center ? "center" : "justify")};
  font-size: 14px;
  line-height: 1.7;
`

export const BadgesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const Badge = styled.div`
  margin-top: 10px;
  width: 23%;

  @media (max-width: 680px) {
    width: 48%;
  }
`

export const BadgeTitle = styled.h3`
  color: #fff;
  font-family: "ZonaProBlack";
  text-align: center;
  font-size: 14px;
`
export const BadgeCircle = styled.div`
  background: #617b34;
  margin: 4px auto;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`

export const BigText = styled.span`
  font-family: "ZonaProBlack";
  font-size: 52px;
  line-height: 0.9;
`

export const SmallText = styled(BigText)`
  font-size: 24px;
`

export const SubText = styled.span`
  font-size: 13px;
  text-align: center;
  text-transform: uppercase;
  max-width: 90px;
`
