import styled from "styled-components"
import Img from "gatsby-image"

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ece7e0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: center;
  padding: 20px;
  flex-wrap: wrap;
`

export const Column = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &:nth-child(2n + 1) {
    margin-right: 2%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0 !important;
  }
`

export const Image = styled(Img)`
  width: 240px;
`

export const Title = styled.h2`
  color: #51197a;
  font-family: "ZonaProBlack";
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  padding: 20px 0 10px;
`

export const Text = styled.p`
  padding-bottom: 16px;
  color: #51197a;
  text-align: center;
  font-size: 14px;
  line-height: 1.7;
  max-width: 380px;
`
