import React from "react"

import * as S from "./styles"

const title = {
  pt: "O açaí também traz diversos benefícios a quem o consome",
  en: "Açaí also brings various benefits to those who consume it",
  es: "El açaí también trae diversos beneficios a quien lo consume",
}

const b1Title = {
  pt: "Prevenção do câncer",
  en: "Cancer Prevention",
  es: "Prevención del cáncer",
}

const b1Text = {
  pt:
    "O açaí contém antocianinas, antioxidantes e ácido oleico, que atrasa o surgimento de tumores, bloqueia os genes que causam a doença e faz as células cancerígenas se autodestruírem.",
  en:
    "Açaí contains anthocyanins, antioxidants and oleic acid which may delay the onset of tumors, and may blocks the genes that cause the disease and makes the carcinogenic cells autodestruct.",
  es:
    "El açaí contiene antocianinas, antioxidantes y ácido oleico, que retrasa el surgimiento de tumores, bloquea los genes que causan la enfermedad y hace que las células cancerígenas se autodestruyan.",
}

const b2Title = {
  pt: "Ajuda ao sistema imunológico",
  en: "Aids the Immune System",
  es: "Ayuda al sistema inmunológico",
}

const b2Text = {
  pt:
    "O açaí contém antioxidantes e altos níveis de vitamina C que auxiliam na manutenção da imunidade.",
  en:
    "Açaí contains antioxidants and high levels of vitamin C which may assist in strengthening the immune system.",
  es:
    "El açaí contiene antioxidantes y altos niveles de vitamina C que ayudan en el mantenimiento de la inmunidad.",
}

const b3Title = {
  pt: "Coração saudável",
  en: "Healthy Heart",
  es: "Corazón sano",
}

const b3Text = {
  pt:
    "O suco de açaí protege as artérias do endurecimento (aterosclerose), além disso a gordura presente no fruto é parecida com a do azeite de oliva, isto é, faz bem a todo o sistema cardiovascular.",
  en:
    "Açaí juice may protect the arteries from hardening (atherosclerosis), besides this, the fat present in the fruit is similar to the one in olive-oil, benefiting the entire cardiovascular system. ",
  es:
    "El jugo de açaí protege las arterias del endurecimiento (arterosclerosis), además la grasa presente en el fruto es parecida a la del aceite de oliva, es decir, hace bien a todo el sistema cardiovascular.",
}

const b4Title = {
  pt: "Anti-inflamatório",
  en: "Anti-inflammatory",
  es: "Anti-inflamatorio",
}

const b4Text = {
  pt:
    "Resultados promissores em pesquisas indicam que o açaí contribui com a redução de sintomas entre os pacientes com enfermidades provocadas por inflamação crônica, doenças autoimunes e asma.",
  en:
    "The results of promising research have indicated that açaí contributes to a reduction of symptoms present in patients that suffer from chronic inflammation, auto immune disease and asthma. ",
  es:
    "Los resultados prometedores en investigaciones indican que el açaí contribuye con la reducción de síntomas entre los pacientes con enfermedades provocadas por inflamación crónica, enfermedades autoinmunes y asma.",
}

const b5Title = {
  pt: "Redução do colesterol",
  en: "Cholesterol Reduction",
  es: "Reducción del colesterol",
}

const b5Text = {
  pt:
    "As antocianinas previnem o câncer quando associadas à diminuição dos níveis de colesterol.",
  en:
    "Anthocyanins may prevent cancer when associated with a reduction of cholesterol levels.",
  es:
    "Las antocianinas previenen el cáncer cuando se asocian a la disminución de los niveles de colesterol.",
}

const b6Title = {
  pt: "Proteção contra o Alzheimer",
  en: "Combats Alzheimer’s",
  es: "Protección contra el Alzheimer",
}

const b6Text = {
  pt:
    "A fruta possui substâncias que deixam o cérebro em alerta por mais tempo, além de resguardar o órgão de danos que podem evoluir para um quadro de Alzheimer.",
  en:
    "The fruit possesses substances that allow the brain to stay alert for longer, further safeguarding the organ from damage that can evolve into a case of Alzheimer’s.",
  es:
    "La fruta posee sustancias que dejan al cerebro en alerta por más tiempo, además de resguardar el órgano de daños que pueden evolucionar hacia un cuadro de Alzheimer.",
}

const b7Title = {
  pt: "Antienvelhecimento",
  en: "Antiaging",
  es: "Antienvejecimiento",
}

const b7Text = {
  pt:
    "Flavonoides e antocianinas presentes no fruto auxiliam na proteção das células, reduzindo sua degeneração e proporcionando um corpo mais saudável e jovem.",
  en:
    "Flavonoids and anthocyanins present in the fruit may assist in protecting the cells, reducing their degeneration and providing a healthier a more youthful body.",
  es:
    "Flavonóides y antocianinas presentes en el fruto ayudan en la protección de las células, reduciendo su degeneración y proporcionando un cuerpo más sano y joven.",
}

const Benefits = ({ language }) => (
  <S.ComponentWrapper>
    <S.Container>
      <S.H3>{title[language]}</S.H3>
      <S.BlockWrapper>
        <S.Block4>
          <S.Title>{b1Title[language]}</S.Title>
          <S.Text>{b1Text[language]}</S.Text>
        </S.Block4>
        <S.Block4>
          <S.Title>{b2Title[language]}</S.Title>
          <S.Text>{b2Text[language]}</S.Text>
        </S.Block4>
        <S.Block4>
          <S.Title>{b3Title[language]}</S.Title>
          <S.Text>{b3Text[language]}</S.Text>
        </S.Block4>
        <S.Block4>
          <S.Title>{b4Title[language]}</S.Title>
          <S.Text>{b4Text[language]}</S.Text>
        </S.Block4>
      </S.BlockWrapper>
      <S.BlockWrapper>
        <S.Block3>
          <S.Title>{b5Title[language]}</S.Title>
          <S.Text>{b5Text[language]}</S.Text>
        </S.Block3>
        <S.Block3>
          <S.Title>{b6Title[language]}</S.Title>
          <S.Text>{b6Text[language]}</S.Text>
        </S.Block3>
        <S.Block3>
          <S.Title>{b7Title[language]}</S.Title>
          <S.Text>{b7Text[language]}</S.Text>
        </S.Block3>
      </S.BlockWrapper>
    </S.Container>
  </S.ComponentWrapper>
)

export default Benefits
