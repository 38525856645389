import styled from "styled-components"
import Img from "gatsby-image"

export const Logo = styled(Img)`
  max-width: 400px;
  display: block;
  margin: 90px auto;

  @media (max-width: 980px) {
    max-width: 300px;
    margin: 50px auto;
  }

  @media (max-width: 768px) {
    max-width: 220px;
    margin: 40px auto;
  }
`
