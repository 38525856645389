const badges = {
  pt: [
    { title: "Vitamina B1", bigText: "9", smallText: "x", subText: "mais" },
    { title: "Vitamina C", bigText: "8", smallText: "x", subText: "mais" },
    { title: "Ferro", bigText: "118", smallText: "x", subText: "mais" },
    {
      title: "Proteína e Cálcio",
      bigText: "=",
      smallText: "",
      subText: "mesmo valor",
    },
    {
      title: "Valor energético",
      bigText: "4",
      smallText: "x",
      subText: "maior",
    },
    { title: "Lipídios", bigText: "3", smallText: "x", subText: "mais" },
    { title: "Carboidratos", bigText: "7", smallText: "x", subText: "mais" },
    { title: "Fósforo", bigText: "1/2", smallText: "", subText: "metade" },
  ],
  en: [
    { title: "Vitamin B1", bigText: "9", smallText: "x", subText: "more" },
    { title: "Vitamin C", bigText: "8", smallText: "x", subText: "more" },
    { title: "Iron", bigText: "118", smallText: "x", subText: "more" },
    {
      title: "Protein and Calcium",
      bigText: "=",
      smallText: "",
      subText: "same value",
    },
    { title: "Energy Value", bigText: "4", smallText: "x", subText: "more" },
    { title: "Lipids", bigText: "3", smallText: "x", subText: "more" },
    { title: "Carbohydrates", bigText: "7", smallText: "x", subText: "more" },
    { title: "Phosphorus", bigText: "1/2", smallText: "", subText: "half of" },
  ],
  es: [
    { title: "Vitamina B1", bigText: "9", smallText: "x", subText: "más" },
    { title: "Vitamina C", bigText: "8", smallText: "x", subText: "más" },
    { title: "Hierro", bigText: "118", smallText: "x", subText: "más" },
    {
      title: "Proteína y Calcio",
      bigText: "=",
      smallText: "",
      subText: "mismo valor",
    },
    { title: "Valor energético", bigText: "4", smallText: "x", subText: "más" },
    { title: "Lípidos", bigText: "3", smallText: "x", subText: "más" },
    { title: "Carbohidratos", bigText: "7", smallText: "x", subText: "más" },
    { title: "Fósforo", bigText: "1/2", smallText: "", subText: "mitad de" },
  ],
}

export default badges
