import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const ibd = {
  pt:
    "O selo IBD ORGÂNICO é atribuído pelo Instituto Biodinâmico (IBD), garantindo a qualidade e a origem dos produtos orgânicos. Considerada a maior certificadora da América Latina e a única certificadora Brasileira de produtos orgânicos com credenciamento IFOAM.",
  en:
    "The IBD Organic seal is awarded by the Instituto Biodinâmico (IBD), guaranteeing the quality and origin of organic products. Considered the largest certifier in Latin America and the only Brazilian certifier of products with IFOAM accreditation.",
  es:
    "El sello IBD ORG NICO es otorgado por el Instituto Biodinâmico (IBD), que garantiza la calidad y el origen de los productos orgánicos. Considerado el mayor certificador de América Latina y el único certificador brasileño de productos con acreditación IFOAM",
}

const organicoBrasil = {
  pt:
    "Certificação orgânica conforme a lei brasileira utilizada para o mercado Nacional.",
  en:
    "Organic certification according to the Brazilian law used for the National market.",
  es:
    "Certificación orgánica según la ley brasileña utilizada para el mercado nacional.",
}

const usdaOrganic = {
  pt:
    "O USDA Organic é o selo creditado pelo Departamento de Agricultura dos Estados Unidos (USDA) que garante acesso ao mercado orgânico Norte Americano.",
  en:
    "USDA Organic is the seal accredited by the United States Department of Agriculture (USDA) that guarantees access to the North American organic market",
  es:
    "USDA Organic es el sello acreditado por el Departamento de Agricultura de los Estados Unidos (USDA) que garantiza el acceso al mercado orgánico de América del Norte.",
}

const ce = {
  pt:
    "Certificação que concede a comercialização e produção de produtos orgânicos atendem as normas estabelecidas ao mercado da União Europeia.",
  en:
    "Certification that guarantees the commercialization and production of organic products that meet the standards established for the European Union market.",
  es:
    "Certificación que garantiza la comercialización y producción de productos orgánicos que cumplen con las leyes establecidas para el mercado de la Unión Europea.",
}

const kosher = {
  pt:
    "Certificação Kosher assegura que os produtos fabricados atendem as normas específicas que regem a dieta alimentar judaica ortodoxa. O selo Kosher demonstra que os produtos processados têm em sua composição a ausência de ingredientes derivados de leite ou carne.",
  en:
    "Kosher certification ensures that the products manufactured comply the specific standards governing the Orthodox Jewish diet. The Kosher seal demonstrates that the processed products have in their composition the absence of ingredients derived from milk or meat.",
  es:
    "La certificación kosher asegura que los productos fabricados cumplen con los estándares específicos que rigen la dieta judía ortodoxa. El sello Kosher demuestra que los productos procesados ​​tienen en su composición la ausencia de ingredientes derivados de la leche o la carne.",
}

const Certifications = ({ language }) => {
  const {
    ibdBadgeImage,
    organicoBadgeImage,
    usdaBadgeImage,
    ueBadgeImage,
    kosherBadgeImage,
  } = useStaticQuery(
    graphql`
      query {
        ibdBadgeImage: file(
          relativePath: { eq: "acai-bello-fruto-selo-ibd-organico.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        organicoBadgeImage: file(
          relativePath: {
            eq: "acai-bello-fruto-selo-produto-organico-brasil.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        usdaBadgeImage: file(
          relativePath: { eq: "acai-bello-fruto-selo-usda-organic.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ueBadgeImage: file(
          relativePath: {
            eq: "acai-bello-fruto-selo-agricultura-biologica-uniao-europeia.png"
          }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        kosherBadgeImage: file(
          relativePath: { eq: "acai-bello-fruto-selo-kosher.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.ComponentWrapper>
      <S.Container>
        <S.Column>
          <S.Image fluid={ibdBadgeImage.childImageSharp.fluid} />
          <S.Title>IBD ORGÂNICO</S.Title>
          <S.Text>{ibd[language]}</S.Text>
        </S.Column>
        <S.Column>
          <S.Image fluid={organicoBadgeImage.childImageSharp.fluid} />
          <S.Title>ORGÂNICO BRASIL</S.Title>
          <S.Text>{organicoBrasil[language]}</S.Text>
        </S.Column>
        <S.Column>
          <S.Image fluid={usdaBadgeImage.childImageSharp.fluid} />
          <S.Title>USDA-ORGANIC</S.Title>
          <S.Text>{usdaOrganic[language]}</S.Text>
        </S.Column>
        <S.Column>
          <S.Image fluid={ueBadgeImage.childImageSharp.fluid} />
          <S.Title>AGRICULTURA BIOLÓGICA (CE)</S.Title>
          <S.Text>{ce[language]}</S.Text>
        </S.Column>
        <S.Column>
          <S.Image fluid={kosherBadgeImage.childImageSharp.fluid} />
          <S.Title>AGRICULTURA BIOLÓGICA (CE)</S.Title>
          <S.Text>{kosher[language]}</S.Text>
        </S.Column>
      </S.Container>
    </S.ComponentWrapper>
  )
}

export default Certifications
