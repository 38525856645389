import React from "react"

import * as S from "./styles"

const videoLang = {
  pt: "https://www.youtube.com/embed/_uI2JoFjz9M",
  en: "https://www.youtube.com/embed/iex8AJJNdzc",
  es: "https://www.youtube.com/embed/UTXH3fBrflU",
}

const VideoSection = ({ language }) => (
  <S.ComponentWrapper>
    <S.Container>
      <S.VideoContainer>
        <iframe
          width="560"
          height="315"
          src={videoLang[language]}
          frameBorder="0"
          allowFullScreen
          title="Video Bello Fruto"
        ></iframe>
      </S.VideoContainer>
    </S.Container>
  </S.ComponentWrapper>
)

export default VideoSection
