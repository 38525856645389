import styled from "styled-components"

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ece7e0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 20px;
`

export const H3 = styled.h3`
  font-family: "ZonaProBlack";
  font-size: 18px;
  line-height: 1;
  color: #51197a;
  margin: 12px 0 36px;
  text-align: center;
`

export const BlockWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
    margin-bottom: 0 !important;
  }
`

export const Block4 = styled.div`
  width: 22%;
  background: #51197a;
  color: #fff;
  padding: 20px;

  &:not(:last-child) {
    margin-right: 4%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
`

export const Block3 = styled.div`
  width: 29.333%;
  background: #51197a;
  color: #fff;
  padding: 20px;

  &:not(:last-child) {
    margin-right: 4%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 10px;
  }
`

export const Title = styled.h3`
  font-family: "ZonaProBlack";
  font-size: 18px;
  padding-bottom: 10px;
  line-height: 1;
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 1.7;
`
