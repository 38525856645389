import React from "react"
import { Parallax } from "react-parallax"
import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const Hero = () => {
  const { logoImage } = useStaticQuery(
    graphql`
      query {
        logoImage: file(relativePath: { eq: "acai-bello-fruto-logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <Parallax
      blur={0}
      bgImage={require("../../images/acai-bello-fruto-bg-header.jpg")}
      bgImageAlt="Açaí Bello Fruto"
      strength={200}
      style={{ marginTop: "94px" }}
    >
      <div>
        <S.Logo fluid={logoImage.childImageSharp.fluid} />
      </div>
    </Parallax>
  )
}

export default Hero
