import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const title = {
  pt: "Fale com a gente",
  en: "Talk to us",
  es: "Hable con nosostros",
}

const text = {
  pt:
    "Mande uma mensagem preenchendo o formulário ao lado, ou se preferir, entre em contato conosco por outros meios de comunicação.",
  en:
    "Send us a message by filling out the form beside. Or, if you prefer, contact us via other means of communication.",
  es:
    "Envíe un mensaje rellenando el formulario al lado, o si lo prefiere, entre en contacto con nosotros por otros medios de comunicación.",
}

const nome = {
  pt: "Nome",
  en: "Name",
  es: "Nombre",
}

const telefone = {
  pt: "Telefone",
  en: "Telephone",
  es: "Teléfono",
}

const mensagem = {
  pt: "Mensagem",
  en: "Message",
  es: "Mensagem",
}

const enviar = {
  pt: "Enviar",
  en: "Send",
  es: "Enviar",
}

const Contact = ({ language }) => {
  const { mapImage } = useStaticQuery(
    graphql`
      query {
        mapImage: file(relativePath: { eq: "bello-fruto-mapa.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1080) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.ComponentWrapper>
      <S.Container>
        <S.Title>{title[language]}</S.Title>
        <S.Column>
          <S.Text>{text[language]}</S.Text>
          <S.Text black>
            BELLO FRUTO INDÚSTRIA, COMÉRCIO, IMPORTAÇÃO & EXPORTAÇÃO DE POLPAS
            DA AMAZÔNIA LTDA-ME
          </S.Text>
          <S.Text>
            Rodovia Castanhal Curuçá KM 07 Tv. José de Alencar s/n. Zona Rural
            Castanhal – PA
          </S.Text>
          <S.Text black>
            +55 (91) 9 8802-0255
            <br />
            +55 (91) 9 8801-5000
            <br />
            <br />
            <a
              href="https://api.whatsapp.com/send?phone=5591991567612&text=Ol%C3%A1%2C%20estou%20entrando%20contato%20a%20partir%20do%20seu%20site."
              target="_blank"
            >
              <S.CWhatsapp />
              +55 (91) 9 9156-7612
            </a>
          </S.Text>
          <S.Text>
            <a href="mailto:comercial@acaibellofruto.com.br">
              comercial@acaibellofruto.com.br
            </a>
          </S.Text>
        </S.Column>
        <S.Column>
          <form
            id="contactform"
            action="https://formsubmit.io/send/comercial@acaibellofruto.com.br"
            method="POST"
          >
            <input
              name="_redirect"
              type="hidden"
              id="name"
              value="https://bellofruto.com.br/enviado"
            />
            <input
              type="text"
              name="nome"
              id="nome"
              placeholder={nome[language]}
              required
            />
            <input
              type="text"
              name="email"
              id="email"
              placeholder="Email"
              required
            />
            <input
              type="text"
              name="telefone"
              id="telefone"
              placeholder={telefone[language]}
              required
            />
            <input
              name="_formsubmit_id"
              type="text"
              style={{ display: "none" }}
            ></input>
            <textarea
              name="Mensagem"
              id="Mensagem"
              rows="4"
              placeholder={mensagem[language]}
            />
            <input type="submit" value={enviar[language]} />
          </form>
        </S.Column>
        <S.Image fluid={mapImage.childImageSharp.fluid} />
        <S.SubText href="https://goo.gl/maps/VpQfJkj1AdT2">
          https://goo.gl/maps/VpQfJkj1AdT2
        </S.SubText>
      </S.Container>
    </S.ComponentWrapper>
  )
}

export default Contact
