import React from "react"

import * as S from "./styles"

const cta = {
  pt: "Conheça nossa linha completa",
  en: "GET TO KNOW OUR COMPLETE PRODUCT LINE",
  es: "CONOZCA NUESTRA LÍNEA COMPLETA",
}
const l1 = {
  pt: "Clique e conheça nossas produtos.",
  en: "Click and discover our products.",
  es: "Haga clic y conozca nuestros productos.",
}
const l2 = {
  pt: "Todos produzidos dentro dos mais rígidos padrões de qualidades.",
  en: "Produced within the strictest quality standards.",
  es: "Todos producidos dentro de los más rígidos patrones de calidad.",
}

const Products = ({ language }) => (
  <S.ComponentWrapper>
    <S.Container>
      <S.Banner>
        <S.BannerLink to="/produtos">{cta[language]}</S.BannerLink>
      </S.Banner>
      <S.Text>
        {l1[language]}
        <br />
        {l2[language]}
      </S.Text>
    </S.Container>
  </S.ComponentWrapper>
)

export default Products
