import React, { useContext } from "react"

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import Header from "../components/Header"
import Hero from "../components/Hero"
import About from "../components/About"
import Divider from "../components/Divider"
import VideoSection from "../components/VideoSection"
import Fruit from "../components/Fruit"
import Benefits from "../components/Benefits"
import Certifications from "../components/Certifications"
import Products from "../components/Products"
import Contact from "../components/Contact"
import { GlobalStateContext } from "../components/context/GlobalContextProvider"

const divider1 = {
  pt: "Saiba mais sobre a Bello Fruto",
  en: "Learn more about Bello Fruto",
  es: "Más información sobre Bello Fruto",
}

const divider2 = {
  pt: "Qualidade certificada",
  en: "Certified quality",
  es: "Calidad certificada",
}

const IndexPage = () => {
  const { language } = useContext(GlobalStateContext)

  return (
    <Layout>
      <SEO title="Home" />
      <Header />
      <Hero />
      <div id="quemsomos">
        <About language={language} />
      </div>
      <Divider content={divider1[language]} />
      <VideoSection language={language} />
      <div id="acai">
        <Fruit language={language} />
      </div>
      <Benefits language={language} />
      <Divider content={divider2[language]} />
      <Certifications language={language} />
      <Products language={language} />
      <div id="contato">
        <Contact language={language} />
      </div>
      <Divider content="Bello Fruto" />
    </Layout>
  )
}

export default IndexPage
