import styled from "styled-components"
import Img from "gatsby-image"
import { Whatsapp } from "@styled-icons/fa-brands/Whatsapp"

export const CWhatsapp = styled(Whatsapp)`
  max-width: 20px;
  margin-right: 4px;
`

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ece7e0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
`

export const Column = styled.div`
  width: 48%;

  %:not(:last-child) {
    margin-right: 2%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0 !important;
  }

  input,
  textarea {
    font-family: "ZonaProBlack";
    font-size: 14px;
    padding: 16px;
    width: 100%;
    margin-bottom: 10px;
    border: none;
  }

  input[type="submit"] {
    background: #51197a;
    color: #fff;
    max-width: 120px;
    margin-left: auto;
    display: block;
  }
`

export const Title = styled.h2`
  color: #51197a;
  font-family: "ZonaProBlack";
  text-transform: uppercase;
  font-size: 30px;
  text-align: center;
  padding-bottom: 10px;
  width: 100%;
`

export const Text = styled.p`
  padding-bottom: 16px;
  color: #51197a;
  text-align: right;
  font-size: 14px;
  line-height: 1.7;
  font-family: ${props => (props.black ? "ZonaProBlack" : "inherit")};

  @media (max-width: 768px) {
    text-align: center;
  }

  a {
    color: #51197a;
  }
`

export const Image = styled(Img)`
  margin: 40px 0 10px;
  width: 100%;
`

export const SubText = styled.a`
  color: #51197a;
  text-decoration: none;
  font-family: "ZonaProBlack";
  text-align: center;
  display: inline-block;
  width: 100%;
`
