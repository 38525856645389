import styled from "styled-components"
import { Link } from "gatsby"

import products from "../../images/bello-fruto-produtos-bg.jpg"

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background: url(${products}) center/cover;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 120px;
`

export const Banner = styled.div`
  border: solid 6px white;
  display: inline-block;
  padding: 17px 15px 10px;
  margin-bottom: 20px;
`

export const BannerLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-family: "ZonaProBlack";
  font-size: 30px;
  text-transform: uppercase;
  max-width: 300px;
  text-align: center;
  display: inline-block;
`

export const Text = styled.p`
  padding-bottom: 16px;
  color: #fff;
  text-align: center;
  font-size: 14px;
  line-height: 1.7;
`
