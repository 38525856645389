import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import * as S from "./styles"

const title = {
  pt: "Sobre a empresa",
  en: "About the company",
  es: "Sobre la empresa",
}

const p1 = {
  pt:
    "Concentrada no centro da maior região produtora de açaí da Amazônia, situada na cidade de Castanhal no estado do Pará, a Açaí Bello Fruto trabalha com matéria prima de alta qualidade, investindo constantemente na fabricação de produtos inovadores, além de contar com uma competente equipe de colaboradores que são treinados para garantir a qualidade nos processos de produção.",
  en:
    "Surrounded by the largest açaí producing region of the Amazon and based in the city of Castanhal, state of Pará, Açai Bello Fruto works with the highest quality raw-material available. With our constant investment in the production of innovative products, we are able to count on an experienced and well-trained team of professionals that guarantee the quality of our production processes.",
  es:
    "En el centro de la mayor región productora de açaí de la Amazonia, situada en la ciudad de Castanhal en el estado de Pará, Açaí Bello Fruto trabaja con materia prima de alta calidad, invirtiendo constantemente en la fabricación de productos innovadores, además de contar con un equipo de personal competente y calificado para garantizar la calidad en los procesos de producción.",
}

const p2 = {
  pt:
    "A Bello Fruto possui um mix de polpas à base de açaí, rico em ferro, fibras, carboidratos e proteínas, tendo ainda a cremosidade, a cor e o sabor original da fruta. Entregamos nossos produtos para grandes redes de Fast Food, restaurantes, supermercados, sorveterias e lojas especializadas em sucos.",
  en:
    "Bello Fruto possesses a mix of açaí-based pulps which hold the original creaminess, color and flavor of the fruit, in addition, being rich in iron, fiber, carbohydrates and proteins. We deliver all our products to the largest chains of fast food outlets, restaurants, supermarkets, ice-cream parlors, and specialized juice shops.",
  es:
    "Bello Fruto posee una mezcla de pulpas a base de açaí, rico en hierro, fibras, carbohidratos y proteínas, aún manteniendo la cremosidad, el color y el sabor original de la fruta. Entregamos nuestros productos para grandes cadenas de comida rápida, restaurantes, supermercados, heladerías y tiendas especializadas en jugos.",
}

const About = ({ language }) => {
  const { fabricImage } = useStaticQuery(
    graphql`
      query {
        fabricImage: file(
          relativePath: { eq: "acai-bello-fruto-fabrica.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )
  return (
    <S.ComponentWrapper>
      <S.Container>
        <S.Column>
          <S.Title>{title[language]}</S.Title>
          <S.Text>{p1[language]}</S.Text>
          <S.Text>{p2[language]}</S.Text>
        </S.Column>
        <S.Column>
          <S.Image fluid={fabricImage.childImageSharp.fluid} />
        </S.Column>
      </S.Container>
    </S.ComponentWrapper>
  )
}

export default About
