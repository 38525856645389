import styled from "styled-components"
import Img from "gatsby-image"

export const ComponentWrapper = styled.section`
  display: flex;
  justify-content: center;
  background-color: #ece7e0;
`

export const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  display: flex;

  justify-content: space-between;
  padding: 20px;

  @media (max-width: 768px) {
    display: block;
  }
`

export const Column = styled.div`
  width: 48%;

  %:not(:last-child) {
    margin-right: 2%;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`

export const Title = styled.h2`
  color: #51197a;
  font-family: "ZonaProBlack";
  text-transform: uppercase;
  font-size: 30px;
  text-align: right;
  padding-bottom: 10px;

  @media (max-width: 768px) {
    text-align: center;
  }
`

export const Text = styled.p`
  padding-bottom: 16px;
  color: #51197a;
  text-align: right;
  font-size: 14px;
  line-height: 1.7;

  @media (max-width: 768px) {
    text-align: left;
  }
`

export const Image = styled(Img)`
  max-width: 100%;
`
